"use client";
import { useState, useRef, useEffect } from "react";
import AbChatLogo from "./assets/img/chat-ab-logo.png";
import Send from "./assets/img/icons/send-icon.svg";
// import Attachment from "./assets/img/icons/attachment-icon.svg";
import ChatBubble from "./chat-bubble";

import "./index.scss";

export default function Home() {
  const chatTxtarea = useRef();

  const autoGrowTxtarea = (e) => {
    chatTxtarea.current.style.height = "24px";
    chatTxtarea.current.style.height = `${e.currentTarget.scrollHeight}px`;
  };
  const resetTxtareaHt = () => {
    chatTxtarea.current.style.height = "24px";
  };

  const [message, setMessage] = useState("");
  const [history, setHistory] = useState([
    {
      role: "assistant",
      content: "Hello! Enter the client Requirement ",
    },
  ]);
  const lastMessageRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (message === "") return;
    setHistory((oldHistory) => [
      ...oldHistory,
      { role: "user", content: message },
    ]);
    setMessage("");
    resetTxtareaHt();
    setLoading(true);
    console.log(process.env.REACT_APP_SERVER_URL, "env var")
    fetch(`${process.env.REACT_APP_SERVER_URL}/enter-user-message`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user_msg: message, file_id: "file-6AzoN0OzTaPjl0o59sjaQMCq" }),
    })
      .then(async (res) => {
        const r = await res.json();
        setHistory((oldHistory) => [...oldHistory, r]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error is ", err);
        alert(err);
      });
  };
  console.log(history, "logger")

  //scroll to bottom of chat
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [history]);

  return (
    <div className="flex flex-col gap-6 w-full items-center max-w-[748px] flex-grow max-h-full">
      <div className="breadcrumb mt-3 flex w-full flex-wrap text-sm text-black/50 items-center">
      </div>
      <form
        className="rounded-xl w-full flex-grow flex flex-col shadow-[0px_0px_16px_rgba(0,0,0,0.2)] max-h-full overflow-clip bg-[#FCFCFC]"
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <div className="flex bg-primary p-4">
          <img src={AbChatLogo} alt="" />
        </div>
        <div className="overflow-y-scroll flex flex-col gap-5 py-4 px-7 h-full">
          {history.map((message, idx) => {
            const isLastMessage = idx === history.length - 1;
            switch (message.role) {
              case "assistant":
                return (
                  <ChatBubble
                    message={message.content}
                    isUser={false}
                    chatRef={isLastMessage ? lastMessageRef : null}
                  />
                );
              case "user":
                return (
                  <ChatBubble
                    key={idx}
                    message={message.content}
                    isUser={true}
                    chatRef={isLastMessage ? lastMessageRef : null}
                  />
                );
              default:
                return 0
            }
          })}
        </div>
        {loading && (
          <div
            ref={lastMessageRef}
            className="px-6 py-2 text-black/50 text-xs flex items-center gap-x-1"
          >
            <span>upwork automation bot evaluating</span>
            <div className="typing typing-xs mt-0.5">
              <span className="typing-dot"></span>
              <span className="typing-dot"></span>
              <span className="typing-dot"></span>
            </div>
          </div>
        )}
        {/* input area */}
        <div className="flex sticky bottom-0 w-full px-4 pb-4">
          <div className="w-full relative">
            <div className="flex rounded w-full h-full border bg-white px-4 py-3 pr-16 text-base border-[#E5E5E5] focus-within:border-primary">
            {/* <div 
              className="flex cursor-pointer"
            >
              <input className="hidden" id="file" type="file" />
              <img className="max-w-[18px]" src={Attachment} alt="" />
            </div> */}
              <textarea
                ref={chatTxtarea}
                aria-label="chat input"
                value={message}
                onChange={(e) => {
                  console.log(message);
                  setMessage(e.target.value);
                  autoGrowTxtarea(e);
                }}
                placeholder="Ask anything"
                className="w-full focus:outline-none resize-none text-[15px] max-h-[60px] overflow-y-auto"
                style={{ height: "24px" }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleClick();
                  }
                }}
              />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleClick();
              }}
              className="absolute right-2.5 top-1/2 transform -translate-y-1/2 cursor-pointer hover:bg-primary/5 focus:bg-primary/10 p-2 rounded-full"
              type="submit"
              aria-label="Send"
              disabled={!message || loading}
            >
              <img className="max-w-[48px]" src={Send} alt="" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
