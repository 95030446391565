import Layout from "./layout.js";
import Home from "./page.js";

function App() {
  return (
    <Layout>
      <Home />
    </Layout>
  );
}

export default App;
