// ChatBubble.js
import React from "react";

const ChatBubble = ({ message, isUser, chatRef }) => {
  return (
    <div
      ref={chatRef}
      className={`flex ${
        !isUser ? "items-start justify-start" : "items-end justify-end"
      }`}
    >
      <div
        className={`relative ${
          !isUser ? "bg-[#E3D9FF] chatBoxAi" : "bg-[#E5E5E5] chatBoxUser"
        }  text-black/90 rounded-lg py-2 px-4 max-w-[80%] shadow-sm`}
      >
        <p className="text-sm font-medium overflow-hidden text-ellipsis whitespace-pre-wrap">
          {message}
        </p>
      </div>
    </div>
  );
};

export default ChatBubble;
